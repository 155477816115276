import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import FileSaver from "file-saver";

export const useMainStore = defineStore("mainStore", () => {
  const birthday = "1975-07-26";
  let age = ref(0);
  const myPagesJson = ref([]);
  const mySitesJson = ref([]);
  const myPhotosJson = ref([]);
  const myMusicJson = ref([]);
  const myHobbyJson = ref([]);
  const myHandmadeJson = ref([]);
  const myDysnaiJson = ref([]);
  const myTarifJson = ref([]);
  const myPotreblenieJson = ref([]);
  const myAbbreviationJson = ref([]);
  const myExceptionJson = ref([]);
  const myNormJson = ref([]);

  const sumJson = ref([]);
  const activeJson = ref([]);
  const album = ref([]);
  let fz = ref(18);
  const fzStep = ref(1);
  const fzpx = ref("18px");
  let lh = ref(1.5);
  const lhStep = ref(0.04);
  const lhem = ref("1.5em");
  let fontSize = ref({
    fontSize: "50px",
    lineHeight: "1.5em",
  });
  const screen = ref("PC");
  const appHeight = ref(window.innerHeight);
  const ph = ref(0);
  const mainPH = ref(0);
  let mainH = ref("100vh");
  const footerH = ref(6); //Одновременно менять в файле variables.sass
  const headerH = ref(6); //Одновременно менять в файле variables.sass
  const dysnaiHeaderH = ref("9");
  // const dysnaiHeaderHpx = ref("9vh");
  const dysnaiMainH = ref("");
  // const dysnaiMainHpx = ref("");
  const dysnaiPanH = ref("");
  const activeDysnaiName = ref("pred");

  const view = ref("links");
  const photoCurrentPage = ref([]);
  const activePage = ref("/");
  // const pat = "http://localhost:8080/";
  // const path = ref(`/assets/store/`);
  const path = ref("https://rasvv.ru/assets/store/");
  // const imgpath = `/assets/img`;
  const imgpath = ref("https://rasvv.ru/assets/img");
  const extension = ref("jpg");

  const bcg_img = ref(imgpath + "background_main.jpg");

  const router = useRouter();

  const setMainH = () => {
    // appHeight.value = 1065
    if (window.innerHeight < 500 || window.innerWidth < 500) {
      if (window.innerHeight > window.innerWidth) {
        screen.value = "mobileH";
        mainH.value = window.innerHeight;
      } else {
        screen.value = "mobileV";
        // mainH.value = window.innerHeight
      }
    } else {
      screen.value = "PC";
      mainH.value =
        window.innerHeight - vhToPx(footerH.value) - vhToPx(headerH.value);
    }
    if (window.innerHeight >= window.innerWidth) {
    }
    dysnaiMainH.value = mainH.value - vhToPx(dysnaiHeaderH.value);

    dysnaiPanH.value = dysnaiMainH.value - 20;

    // mainH.value;
  };

  const vhToPx = (vh) => {
    return (vh * window.innerHeight) / 100;
  };

  const getAge = () => {
    const d = new Date(birthday);
    const now = new Date();

    const addOne =
      now.getMonth() - d.getMonth() > 0
        ? 0
        : now.getMonth() - d.getMonth() == 0 &&
          now.getMonth() - d.getMonth() >= 0
        ? 0
        : -1;

    const diff = now.getFullYear() - d.getFullYear();

    const age1 = diff + addOne;

    let txt = "";
    let count = age1 % 100;
    count >= 5 && count <= 20 ? (txt = "лет") : (count = count % 10);
    count == 1
      ? (txt = "год")
      : count >= 2 && count <= 4
      ? (txt = "года")
      : (txt = "лет");
    age.value = age1 + " " + txt;
  };

  const fetching = async (jsName, myJson) => {
    const res = await fetch(`${path.value}${jsName}.json`);
    const data = await res.json();
    myJson.value = await data;
    sumJson.value.push({
      name: jsName,
      data: myJson.value,
    });
    return myJson.value;
  };

  const fetchAll = async () => {
    setMainH();
    getAge();
    fetching("pages", myPagesJson);

    await fetching("mySites", mySitesJson);
    await fetching("photos", myPhotosJson);
    await fetching("hobby", myHobbyJson);
    await fetching("handmade", myHandmadeJson);
    await fetching("dysnai", myDysnaiJson);
    await fetching("abbreviation", myAbbreviationJson);
    await fetching("exception", myExceptionJson);
    await fetching("tarif", myTarifJson);
    await fetching("norm", myNormJson);
    await fetching("music", myMusicJson);
    await fetching("potreblenie", myPotreblenieJson);
    // const routPath = router.currentRoute.value.path;
    const routName = router.currentRoute.value.name;

    // await router.push(routPath);
    if (routName === "abbreviation") {
      await fetchAbbreviation();
    } else if (routName === " platezh") {
      await fetchPlatezh();
    } else if (routName != "main") {
      await setPage({ name: routName, click: true });
    }

    console.log(router.currentRoute.value);
    window.addEventListener("resize", setMainH);
  };

  const fetchPlatezh = async () => {
    await router.push("/platezh");
  };

  const fetchAbbreviation = async () => {
    await router.push("/abbreviation");
  };

  const setPage = async (an) => {
    await setView("links");
    activePage.value = await an.name;
    await router.push(an.name);
    if (an.click) {
      await setActiveJson(an.name);
    }
    if (an.album) {
      await setPhotoCurrentPage(getAlbum(an.album));
      // setAlbum(an.album),
      await setView("grid");
    }
  };

  const setActiveJson = async (active) => {
    const it = await sumJson.value.filter((item) => item.name === active);

    if (it.length > 0) activeJson.value = it[0].data;
  };

  const setPhotoCurrentPage = (card) => {
    photoCurrentPage.value = card;
  };

  const getAlbum = (al) => {
    return activeJson.value.filter((item) => item.title === al)[0];
  };

  const setView = (sv) => {
    view.value = sv;
  };

  const setAlbum = (al) => {
    album.value = al;
  };

  const setFZ = (f_z) => {
    fz.value = fz.value + fzStep.value * f_z;
    fzpx.value = fz.value + "px";
  };

  const setLH = (l_h) => {
    lh.value = lh.value + lhStep.value * l_h;
    lhem.value = lh.value + "em";
  };

  const setFontSizeUp = () => {
    setLH(1);
    setFZ(1);
  };

  const setFontSizeDown = () => {
    setLH(-1);
    setFZ(-1);
  };

  const saveFile = (array, fileName) => {
    console.log("before saveFile");

    const data = JSON.stringify(array);
    const blob = new Blob([data], { type: "application/json" });
    FileSaver.saveAs(blob, fileName);
  };

  const playMusic = () => {
    audio.play();
  };

  // watch(offsetHeight, () => {
  // 	setMainH();

  // });

  return {
    myPagesJson,
    mySitesJson,
    myPhotosJson,
    myHobbyJson,
    myHandmadeJson,
    myDysnaiJson,
    myTarifJson,
    myPotreblenieJson,
    myAbbreviationJson,
    myExceptionJson,
    myNormJson,
    myMusicJson,
    sumJson,
    activeJson,

    album,
    fz,
    lh,
    fontSize,
    ph,
    mainPH,
    footerH,
    headerH,

    view,
    photoCurrentPage,
    activePage,
    path,
    imgpath,
    bcg_img,
    jsonFile: "",
    fetching,
    fetchAll,
    fetchPlatezh,
    fetchAbbreviation,
    setPhotoCurrentPage,
    setPage,
    setView,
    extension,
    setAlbum,
    getAge,
    age,
    appHeight,
    mainH,
    dysnaiHeaderH,
    dysnaiMainH,
    dysnaiPanH,
    activeDysnaiName,

    setFZ,
    setLH,
    setFontSizeUp,
    setFontSizeDown,
    setActiveJson,
    fzpx,
    lhem,
    saveFile,
    screen,
    playMusic,
  };
});
